import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/projects/banner'
import Project from '../containers/projects/project'

export default () => (
    <Layout>
        <SEO 
            title="Ostrovsky-marketing: примеры проектов"
            description={
                `Увеличить количество лидов в несезон, 
                поднять CTR на 42%, уменьшить CPC на 12% 
                а также многие другие примеры успеха от 
                компании Ostrovsky-marketing.`
            }
        />
        <Banner/>
        <Project/>
    </Layout>
)
